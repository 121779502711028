.main__photos {
  height: 500px;
  width: 60%;
  max-width: 750px;
  min-width: 750px;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 25px;
}

.all-items-container {
  height: 100%;
  display: flex;

  transition: translate;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.photo {
  user-select: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.main__photo-1 {
  background-image: url('../../data/home/1.jpg');
  background-size: cover;
}

.main__photo-2 {
  background-image: url('../../data/home/2.jpg');
  background-size: cover;
}

.main__photo-3 {
  background-image: url('../../data/home/3.jpg');
  background-size: cover;
}

.photos-container {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* margin-top: 5rem;
  margin-left: 14rem; */
}

.arrow {
  font-size: 2rem;
  border-radius: 2rem;
}

@media screen and (max-width: 1400px) {
  .photos-container {
    display: none;
  }
}
