

@media screen and (min-width: 800px) {
  .product-description-container {
    margin: 0 2% 0 2%;
  }
}

.product-description-container{
  margin-top: 2rem;
}

.product-description-title {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.product-description-name {
  cursor: pointer;
}

.product-description-text {
  overflow: hidden;
}

#desc-hidden {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

#desc-expanded {
  max-height: fit-content;
  transition: max-height 0.3s ease-in-out;
}

.triangle-expand {
  font-size: 2rem;
  cursor: pointer;
}

#tr-static {
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
}

#tr-expanded {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
