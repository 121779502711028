#img_4_1 {
  background-image: url('../../data/projects/project_4/1.jpg');
}

#img_4_2 {
  background-image: url('../../data/projects/project_4/2.jpg');
}

#img_4_3 {
  background-image: url('../../data/projects/project_4/3.jpg');
}
#img_4_4 {
  background-image: url('../../data/projects/project_4/4.jpg');
}
#img_4_5 {
  background-image: url('../../data/projects/project_4/5.jpg');
}
#img_4_6 {
  background-image: url('../../data/projects/project_4/6.jpg');
}

#img_4_7 {
  background-image: url('../../data/projects/project_4/7.jpg');
}

#img_4_8 {
  background-image: url('../../data/projects/project_4/8.jpg');
}

#img_4_9 {
  background-image: url('../../data/projects/project_4/9.jpg');
}

#img_4_10 {
  background-image: url('../../data/projects/project_4/10.jpg');
}
