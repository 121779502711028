#about-us-section h1 {
  text-align: center;
  margin-top: '3rem';
  margin-bottom: 0;
}

.flex {
  width: 80%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: left;
}

.about-us__image {
  display: none;
  width: 60%;
  min-width: 270px;
  max-width: 100%;
  height: clamp(200px, 200px + 10vw, 280px);
  background-image: url('../data/home/about-us/team.png');
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.about-us__text {
  font-size: 1.2rem;
  padding: 0 2rem 0 2rem;
}

.about-us__text-background {
  height: 10rem;
  background-image: url('../data/about-us/bg.png');
  background-position: center 711px;
  background-size: auto;
  filter: grayscale(100%);
  /* background-attachment: fixed; */
}

.about-us__text-background h1 {
  margin-top: 0;
  color: #fff;
  line-height: 10rem;
  text-align: center;
}

.about-us-container {
  padding: 2rem 0;
}

@media screen and (min-width: 1400px) {
  .flex {
    justify-content: left;
    flex-direction: row;
    gap: 4rem;
    padding: 0 2rem 0 2rem;
  }

  .about-us__image {
    display: block;
    height: 400px;
    width: 40%;
  }

  .about-us__text {
    width: 60%;
  }
}

