.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.contact-us__form-container  {
  align-self: center;
}

.contact-us__text-container h1 {
  margin-top: 0;
}

.contact-us__text-container,
.contact-us__form-container {
  width: 40%;
}

.contact-us__form-container {
  margin-top: 3rem;
}

.contact-us__form-container label[for='name'] {
  margin-top: 0;
}

.contact-us__form-container form input,
.contact-us__form-container form label,
.contact-us__form-container form textarea {
  display: block;
}

.contact-us__form-container form input,
.contact-us__form-container form textarea,
.contact-us__form-container form select {
  font-family: inherit;
  font-size: 1rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #f7f7f7;
  border: none;
  width: 90%;
}

.contact-us__text-background {
  height: 10rem;
  background-image: url('../data/contacts/bg.jpg');
  background-position: -79px 1613px;
}

.contact-us__text-background h1 {
  margin-top: 0;
  color: #000;
  line-height: 10rem;
  text-align: center;
}
 
input:invalid, textarea:invalid {
  text-decoration: none;
  box-shadow: none;
}

.contact-us__form-container form input,
.contact-us__form-container form select {
  height: 2.5rem;
}

.contact-us__form-container form textarea {
  height: 6.8rem;
}

form label {
  margin-top: 1.5rem;
}

form textarea {
  resize: none;
}

.form-btn {
  margin-top: 2rem;
  width: 90%;
}

.contact-us__text__paragraph {
  width: 90%;
}

.contact-us__text__socials {
  margin-top: 2rem;
}

.contact-us__text-container a {
  display: block;
  color: #000;
}

.contact-us__text-container a + a {
  margin-top: 0.8rem;
}

.contact-us__touch-phone {
  display: none;
}

@media screen and (max-width: 800px) {
  .contact-us-container {
    display: flex;
    padding-top: 2rem;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .contact-us__text-background {
    height: 10rem;
    background-image: url('../data/contacts/bg.jpg');
    background-position: -116px 1287px;
  }

  .contact-us__text-background  h1{
    color: #fff;
  }

  .contact-us__text-container,
  .contact-us__form-container {
    width: 80%;
  }

  .contact-us__text__paragraph {
    width: 100%;
  }

  .contact-us__form-container  {
    align-self: center;
  }

  .contact-us__touch-device {
    display: none;
  }

  .contact-us__touch-phone {
    display: block;
  }
}

.blurred {
  filter: blur(2px);
  pointer-events: none;
}
