#img_3_1 {
  background-image: url('../../data/projects/project_3/1.jpg');
}

#img_3_2 {
  background-image: url('../../data/projects/project_3/2.jpg');
}

#img_3_3 {
  background-image: url('../../data/projects/project_3/3.jpg');
}
#img_3_4 {
  background-image: url('../../data/projects/project_3/4.jpg');
}
#img_3_5 {
  background-image: url('../../data/projects/project_3/5.jpg');
}
#img_3_6 {
  background-image: url('../../data/projects/project_3/6.jpg');
}

#img_3_7 {
  background-image: url('../../data/projects/project_3/7.jpg');
}

#img_3_8 {
  background-image: url('../../data/projects/project_3/8.jpg');
}

#img_3_9 {
  background-image: url('../../data/projects/project_3/9.jpg');
}

#img_3_10 {
  background-image: url('../../data/projects/project_3/10.jpg');
}
