#img_6_1 {
  background-image: url('../../data/projects/project_6/1.jpg');
}

#img_6_2 {
  background-image: url('../../data/projects/project_6/2.jpg');
}

#img_6_3 {
  background-image: url('../../data/projects/project_6/3.jpg');
}
#img_6_4 {
  background-image: url('../../data/projects/project_6/4.jpg');
}
#img_6_5 {
  background-image: url('../../data/projects/project_6/5.jpg');
}
#img_6_6 {
  background-image: url('../../data/projects/project_6/6.jpg');
}

#img_6_7 {
  background-image: url('../../data/projects/project_6/7.jpg');
}

#img_6_8 {
  background-image: url('../../data/projects/project_6/8.jpg');
}

#img_6_9 {
  background-image: url('../../data/projects/project_6/9.jpg');
}

#img_6_10 {
  background-image: url('../../data/projects/project_6/10.jpg');
}
