.error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.error-page a {
  text-decoration: none;
}