.portolio-section-container {
  text-align: center;
  max-width: 1430px;
  margin: 0 auto;
  padding-top: 2rem;
}

.portolio-section-container h1 {
  margin-top: 0;
  font-weight: 700;
}

.portolio-section-container p {
  margin: 0 auto;
  width: 80%;
}

.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 4rem 2rem 0 2rem;
}

.btn-return {
  display: inline-block;
}
