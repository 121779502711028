@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overflow {
  overflow: hidden;
}

#root {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
