.main {
  display: flex;
  justify-content: center;
}

.main-background {
  height: calc(100vh - 5rem);
  width: 100%;
  background-position: center center;
  background-attachment: scroll;
  background-image: url('../../data/home/main/1.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__text {
  width: 40%;
  margin: 0 0 0 2.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
}

.main__text .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__text h1 {
  color: #fff;
  text-align: center;
  margin-top: 0;
}

.main__text button {
  display: block;
  width: 13rem;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.5s ease;

  font-size: 0.8rem;
  font-weight: 700;
}

.main__text button:hover {
  background-color: #5A92E4;
}

.main__text p {
  width: 60%;
  font-size: 1.2rem;
}

.main__text i {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.main-btn a {
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 840px) {
  .main-background {
    height: calc(50vh - 5rem);
  }
}

@media screen and (max-width: 1400px) {
  .main__text {
    margin: 0;
    width: 80%;
  }
  .main__text h1 {
    width: auto;
  }
}
