.product-container,
.product__image,
.product__name {
  min-width: 260px;
  max-width: 340px;
  flex-grow: 1;
}

.product-container {
  display: flex;
  flex-direction: column;
  /* min-width: 340px; */
  text-align: center;
}

.product__image {
  height: 200px;
  border-radius: 25px;
  background-color: #dcdcdc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.product__name {
  /* min-width: 300px; */
  /* max-width: 420px; */
  margin-top: 1rem;
  font-size: 1.2rem;
  opacity: 0.4;
}

#Framing {
  background-image: url('../../data/services/framing.jpg');
}

#Electrical {
  background-image: url('../../data/services/electrical.jpg');
}

#Plumbing {
  background-image: url('../../data/services/plumbing.png');
}

#Insulation {
  background-image: url('../../data/services/insulation.png');
}

#Windows {
  background-image: url('../../data/services/windows.png');
}

#Flooring {
  background-image: url('../../data/services/flooring.jpg');
}

#Siding {
  background-image: url('../../data/services/siding.png');
}

#Gutters {
  background-image: url('../../data/services/gutters.jpg');
}

#Painting {
  background-image: url('../../data/services/painting.webp');
}




