.services-container {
  width: 80%;
  margin: 0 auto;
  max-width: 1600px;
  /* margin: 1rem 2rem 0 2rem; */
}

.services__description {
  margin: 2rem 8% 0 8%;
  text-align: center;
}

.services__text-background {
  height: 10rem;
  background-image: url('../data/services/bg.jpg');
  background-position: center 300px;
}

.services__text-background h1 {
  margin-top: 0;
  color: #fff;
  line-height: 10rem;
  text-align: center;
}

.services__text-list {
  list-style-type: none;
}

.services__text-list {
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 500;
}

.services__text-list li {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.services__text-list li::before {
  content: '- ';
}

.services__products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 2rem;
}
