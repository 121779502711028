.footer {
  width: 100%;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  background-image: url('../data/footer/bg.jpg');
}

.footer__blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4b5362;
}

.footer__blue h1 {
  color: #fff;
  font-weight: 300;
}

.footer__blue a {
  padding: 1em 2em;
  border: 1px solid #000;
  text-decoration: none;
  color: #fff;
  background-color: #000;
}

.footer__blue span {
  font-size: 1rem;
}

.footer__black {
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-copyright {
  color: #b4b8c0;
  margin-top: 2rem;
  width: 40%;
}

.footer-accreditations {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* display: none; */
}

.footer-accreditations img {
  width: 5.5rem;
}

.footer-accreditations a:nth-child(4) img{
  width: 3rem;
}

.footer-accreditations,
.footer-contact {
  width: 50%;
}

.copyright-phone {
  display: none;
}

@media screen and (max-width: 1150px) {
  .footer-accreditations {
    flex-direction: column;
  }

  .footer__blue {
    padding-top: 1rem;
  }
  .footer__black {
    padding-top: 2rem;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-copyright,
  .footer-contact {
    width: 80%;
  }

  .footer-copyright {
    margin-top: 0;
  }

  .copyright-phone {
    margin-bottom: 2rem;
    display: block;
  }

  .copyright-device {
    display: none;
  }
}
