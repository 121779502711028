#img_5_1 {
  background-image: url('../../data/projects/project_5/1.jpg');
}

#img_5_2 {
  background-image: url('../../data/projects/project_5/2.jpg');
}

#img_5_3 {
  background-image: url('../../data/projects/project_5/3.jpg');
}
#img_5_4 {
  background-image: url('../../data/projects/project_5/4.jpg');
}
#img_5_5 {
  background-image: url('../../data/projects/project_5/5.jpg');
}
#img_5_6 {
  background-image: url('../../data/projects/project_5/6.jpg');
}

#img_5_7 {
  background-image: url('../../data/projects/project_5/7.jpg');
}

#img_5_8 {
  background-image: url('../../data/projects/project_5/8.jpg');
}

#img_5_9 {
  background-image: url('../../data/projects/project_5/9.jpg');
}

#img_5_10 {
  background-image: url('../../data/projects/project_5/10.jpg');
}
