#img_7_1 {
  background-image: url('../../data/projects/project_7/1.jpg');
}

#img_7_2 {
  background-image: url('../../data/projects/project_7/2.jpg');
}

#img_7_3 {
  background-image: url('../../data/projects/project_7/3.jpg');
}
#img_7_4 {
  background-image: url('../../data/projects/project_7/4.jpg');
}
#img_7_5 {
  background-image: url('../../data/projects/project_7/5.jpg');
}
#img_7_6 {
  background-image: url('../../data/projects/project_7/6.jpg');
}

#img_7_7 {
  background-image: url('../../data/projects/project_7/7.jpg');
}

#img_7_8 {
  background-image: url('../../data/projects/project_7/8.jpg');
}

#img_7_9 {
  background-image: url('../../data/projects/project_7/9.jpg');
}

#img_7_10 {
  background-image: url('../../data/projects/project_7/10.jpg');
}
