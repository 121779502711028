.values-container {
  background-color: #f0f8ff;
  padding: 2rem 0;
}

.values-container .section-flex {
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;
}

.values-container .section-flex .cell {
  width: 50%;
  height: 50%;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 45px;
}

.values-container .section-flex .cell .inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.values-container .section-flex .cell .inner .row {
  align-items: start;
  display: flex;
}

.values-container .section-flex .cell .inner .xs-2 {
  width: 75px;
}

.values-container .section-flex .cell .inner .xs-2 .values-img {
  background-size: contain;
  background-repeat: no-repeat;
}

.values-container .section-flex .cell .inner .xs-10 {
  align-self: flex-end;
  width: calc(100% - 75px);
}

.values-container .section-flex .cell .inner .xs-10 h3 {
  margin-top: 0;
}

.values-time {
  max-width: 45px;
  height: 45px;
  background-image: url('../data/values/time.svg');
}

.values-safe {
  max-width: 45px;
  height: 45px;
  background-image: url('../data/values/safe.svg');
}

.values-communication {
    max-width: 45px;
    height: 45px;
  background-image: url('../data/values/communication.svg');
}

.values-helpfulness {
  max-width: 45px;
  height: 45.65px;
  background-image: url('../data/values/helpfulness.svg');
}

@media screen and (max-width: 950px) {
  .values-container .section-flex .cell {
    width: 100%;
    display: block;
  }

  .values-container .section-flex {
    width: 90%;
  }
}
