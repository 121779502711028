.pop-up-container {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  text-align: center;
  left: calc(50% - 15%);
  top: calc(50% - 15%);
  width: 30%;
  max-width: 430px;
  height: 30%;
  max-height: 200px;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
}

.visible {
  visibility: visible;
}

.visible ~ .contact-us-container {
  filter: blur(2px);
  pointer-events: none;
}

.pop-up-container p {
  font-size: 1.2rem;
}

.pop-up-btn {
  display: block;
  margin-left: auto;
  height: 2rem;
  width: 5rem;
}

.pop-up-x {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .pop-up-container {
    left: calc(50% - 145px);
    top: calc(50% - 125px);
    width: 270px;
    height: fit-content;
    border-radius: 10px;
    padding: 1rem;
    font-size: 0.8rem;
  }
}
