.portfolio__elem-container {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  max-width: 600px;
  text-align: center;
  flex-grow: 1;
}

#portfolio-third {
  background-image: url('../data/projects/project_3/3.jpg');
}

#portfolio-fourth {
  background-image: url('../data/projects/project_4/4.jpg');
}

#portfolio-fifth {
  background-image: url('../data/projects/project_5/5.jpg');
}

#portfolio-sixth {
  background-image: url('../data/projects/project_6/3.jpg');
}

#portfolio-seventh {
  background-image: url('../data/projects/project_7/1.jpg');
}

.portfolio__image {
  position: relative;
  background-color: #d9d9d9;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 360px;
  max-width: 600px;
  height: 300px;
  overflow: hidden;
}

.portfolio__name {
  margin-top: 1rem;
  font-size: 1.2rem;
  opacity: 0.4;
  min-width: 360px;
  max-width: 600px;
}

.portfolio__content {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  transform: translateY(100%);
  background-color: rgba(75, 83, 98, 0.6);
  transition: transform 0.1s ease-in-out;
}

.portfolio__image:hover .portfolio__content {
  transform: translateY(0);
}

.content__title {
  color: #fff;
}

.content__link {
  font-size: 0.9rem;
  padding: 0.5em 1em;
  color: #fff;
  background-color: #000000;
  text-decoration: none;
  transition: background-color ease-in-out 0.15s;
}

.content__link:hover {
  background-color: #272727;
}

.portfolio__content-mobile {
  display: none;
  background-color: rgba(75, 83, 98, 1);
  height: 100px;
}

@media screen and (max-width: 900px) {
  .portfolio__elem-container {
    max-width: 500px;
  }
  .portfolio__content {
    display: none;
  }

  .portfolio__content-mobile {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .portfolio-container,
  .portfolio__image,
  .portfolio__name {
    min-width: 240px;
  }
}
