.introduction-container {
  margin-top: 4rem;
}

.introduction__text {
  width: 100%;
  font-size: 1.2rem;
  max-width: 1320px;
  margin: 2rem auto;
}

.carousel-phone {
  display: none;
}

.content-container {
  max-width: 1420px;
  margin-top: 4rem;
  width: 90%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.introduction-img {
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center center;
}

.first {
  background-image: url('../../data/introduction/first.jpeg');
}

.second {
  background-image: url('../../data/introduction/second.jpeg');
}


.content-container .phone {
  display: none;
}


@media screen and (max-width: 840px) {
  .content-container {
    flex-direction: column;
    width: 90%;
  }
  .introduction-img {
    width: 80%;
    height: 320px;
  }

  .introduction__text {
    width: 80%;
  }

  .content-container .device {
    display: none;
  }

  .content-container .phone {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .introduction__text {
    font-size: 1rem;
  }

  .carousel-phone {
    margin: 0 auto;
    height: 45vw;
    min-height: 200px;
    width: 80vw;
    min-width: 240px;
    padding: 8px;
    display: block;
  }
}
