#reviews-section {
  position: relative;
  margin-top: 4rem;
}

#reviews-section h1 {
  text-align: center;
}

.reviews-container {
  max-width: 1500px;
  width: 80%;
  margin: 4rem auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8rem;
  row-gap: 4rem;
  /* margin: 4rem 6rem 0 6rem; */
}
