.services-background {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../data/home/services/bg.png');
  background-size: cover;
}

.services-background .services-container {
  width: 100%;
  padding: 0 4.5rem;
  text-align: center;
}

.services-container .services-title {
  color: #fff;
  margin-bottom: 8rem;
}

.services-background .services-flex {
  display: flex;
  justify-content: center;
}

.services-background .services-flex .cell {
  width: 20%;
  text-align: center;
  margin-top: 2rem;
}

.services-background .services-flex .cell .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.services-background .services-flex .cell .inner h3 {
  color: #fff;
}

.services-background .services-flex .cell .inner .learn-more-btn {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  text-decoration: none;
  padding: 0.5em 1em;
  border: 1px solid #fff;
  color: #fff;
  transition: opacity .15s;
}

.services-background .services-flex .cell .inner .learn-more-btn:hover {
    opacity: 0.7;
}

.services-background .services-flex .cell .service-img {
  margin: 0 auto;
}

.services-background .services-flex .cell .service-siding {
  background-image: url('../../data/home/services/siding.svg');
  width: 88px;
  height: 75px;
}

.services-background .services-flex .cell .service-windows {
  width: 52px;
  height: 75px;
  background-image: url('../../data/home/services/windows.svg');
}

.services-background .services-flex .cell .service-painting {
  width: 79px;
  height: 75px;
  background-image: url('../../data/home/services/painting.svg');
}

.services-background .services-flex .cell .service-building {
    width: 62px;
    height: 75px;
    background-image: url('../../data/home/services/building.svg');
  }

.services-background .services-flex .cell .service-gutters {
  width: 62px;
  height: 75px;
  background-image: url('../../data/home/services/gutters.svg');
}

@media screen and (max-width: 900px) {
  .services-background {
    min-height: 900px;
    height: 50vh;
  }

  .services-background .services-container {
    padding: 0 2rem;
  }

  .services-container .services-title {
    margin-bottom: 4rem;
  }

  .services-background .services-flex {
    display: block;
  }

  .services-background .services-flex .cell {
    width: 50%;
    display: inline-block;
  }
}
