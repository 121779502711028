.project-container {
  max-width: 1240px;
  padding-top: 2rem;
  margin: 0 auto 0 auto;
  width: fit-content;
}

.project-container h1 {
  margin-top: 0;
}

.project__gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.project__image {
  margin: 10px;
  height: 400px;
  width: 600px;
  background-color: rgb(34, 95, 155);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-return {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0 1.5rem 0;
}

.project-container h1,
.project-container p,
.project-container .btn-return {
  margin-left: 10px;
}

@media screen and (max-width: 1240px) {
  .project-container {
    width: min-content;
  }
}

@media screen and (max-width: 690px) {
  .project-container {
    width: 90%;
    max-width: 390px;
  }

  .project__image {
    max-width: 390px;
    min-width: 300px;
    height: 240px;
    flex-grow: 1;
  }
}
