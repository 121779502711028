.bg {
  background-image: url('./data/home/about-us/bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.text-blue {
  color: #020813 !important;
}

.bg-blue {
  background-color: #020813 !important;
}

.bg-light-blue {
  background-color: #1d283d !important;
}

.btn-blue {
  background-color: #020813 !important;
  color: #fff !important;
  transition: background-color 0.5s ease !important;
}

.btn-blue:hover {
  background-color: #000 !important;
}

.align-center {
  margin: 0 auto;
}