.phone-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: #fff;

  list-style-type: none;
  -webkit-font-smoothing: antialiased;

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1;
}

@media screen and (min-width: 841px) {
  .phone-menu {
    display: none;
  }
}

.phone-menu li {
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
}

.phone-menu li a {
  text-decoration: none;
  color: inherit;
}

.phone-menu .btn {
  text-decoration: none;
  text-align: center;
  line-height: 3.5rem;
} 


.phone-menu li:last-of-type {
  margin-bottom: 3.5rem;
}

.btn {
  width: 10rem;
  height: 3.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

#active {
  transform: none;
}

#active ~ body {
  overflow: hidden;
}
