.header-container {
  height: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header__logo {
  max-width: 16rem;
  width: 20%;
  min-width: 12rem;
  height: 3rem;
  background-color: #fff;
  border-radius: 40px;
  background-image: url('../data/logo.png');
  background-position: center center;
  background-size: 150%;
}

.header-top {
  display: flex;
  justify-content: right;
  gap: 1rem;
}

.header-bottom {
  padding: 1rem;
}

.header_estimate-containter {
  display: flex;
  justify-content: right;
  gap: 0.5rem;
}

.header__estimate-text,
.header__estimate-number {
  width: 8.5rem;
  font-weight: 500;
  height: 2rem;
  border-radius: 2rem;
  padding: 0 0.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  background-color: #fff;
}

.header__estimate-number {
  color: #fff;
  line-height: 2rem;
}

.header__estimate-text {
  display: flex;
}

.header__estimate-text span {
  display: inline-block;
  margin: auto;
}

.header-accreditations {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  width: 50%;
}

.header-accreditations img {
  max-width: 6rem;
  width: 100%;
}

.accreditations-svg {
  height: 3rem;
}

.header__menu-toggle {
  display: none;
  z-index: 2;
}

.header__nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.header__nav li {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
}

.header__nav a:hover,
.header__nav a:focus {
  opacity: 0.8;
}

.header__nav li a {
  text-decoration: none;
  color: inherit;
}

.header__btns {
  width: 16rem;
  display: flex;
  justify-content: space-evenly;
}

.header__btns .btn {
  width: 6rem;
  height: 2rem;
  font-weight: 700;
  font-size: 0.6rem;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  text-align: center;
  line-height: 2rem;
}

.header__btns .btn:hover,
.header__btns .btn:focus {
  opacity: 0.8;
}

.header__menu-toggle button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.burger span:nth-child(1),
.burger span:nth-child(2) {
  margin-bottom: 5px;
}

.burger span {
  display: block;
  width: 33px;
  height: 4px;
  position: relative;

  background-color: #fff;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease, background-color 0.5s ease-in;
}

.burger span:first-child {
  transform-origin: 0% 0%;
}

.burger span:nth-last-child(3) {
  transform-origin: 0% 100%;
}

#toggled span:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#toggled span:nth-last-child(3) {
  transform: rotate(45deg) translate(-2px, -4px);
}

#toggled span {
  transform: rotate(-45deg) translate(0, -1px);
  background-color: #000;
}

@media screen and (max-width: 1200px) {
  .header__menu-toggle {
    display: block;
  }
  .header__nav,
  .header__btns {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .header-bottom {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .header_estimate-containter {
    display: none;
  }
}
