.reviews__review {
  min-width: 320px;
  max-width: 500px;
  padding: 0.5rem 2rem 2rem 2rem;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.reviews__review__title {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
}

.reviews__review img {
  width: 70px;
  height: 70px;
}

.reviews__review__text {
  padding: 1rem 1rem 1rem 0;
  opacity: 0.6;
}